import { ArrowRight } from '@phosphor-icons/react';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { Box, BoxProps, Grid, useMediaQuery, useTheme, styled, css } from '@mui/material';
import { graphql, navigate } from 'gatsby';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import Seo from '../app/common/components/Seo';
import communicateHero from '../assets/images/communicate-hero.svg';
import SwContainer from '../components/v1/SwContainer';
import SwLayout from '../components/v1/SwLayout';
import SwLink from '../components/v1/SwLink';
import SwSectionCard from '../components/v1/SwSectionCard';
import SwSubMenu from '../components/v1/website/SwSubMenu';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';
import { black, grey3, grey5, grey6 } from '../lib/components/bedrock/SwColors';
import SwLine from '../lib/components/bedrock/layout/SwLine';
import SwSpacer from '../lib/components/bedrock/layout/SwSpacer';
import SwTypography from '../lib/components/bedrock/typography/SwTypography';
import SwWebsiteContainer from '../lib/components/layout/SwWebsiteContainer';
import { zIndexes } from '../lib/enums/zIndexes';

const Page = styled('div')`
    background-color: ${grey6};
`;

const StyledBox = styled(Box)`
    &:hover {
        cursor: pointer;
    }
`;

const StyledImageDiv = styled(Box)<{ isSmallScreen: boolean }>`
    position: ${({ isSmallScreen }) => (isSmallScreen ? 'initial' : 'absolute')};
    width: 615px;
    max-height: 615px;
    right: 0;
    top: 0;
    overflow: hidden;
    ${({ theme }) => theme.breakpoints.down('md')} {
        width: 100%;
    }
`;

const StyledContainer = styled(SwContainer)`
    position: relative;
    min-height: 660px;
`;

const StyledGrid = styled(Grid)`
    z-index: ${zIndexes.EDIT_OVERLAY};
    position: relative;
`;

const StyledImage = styled('img')<{ isSmallScreen: boolean }>`
    margin: ${({ isSmallScreen }) => (isSmallScreen ? '50px 0' : '0')};
`;

const Header = styled('div')`
    padding-block-start: 32px;
    padding-block-end: 64px;
`;

const StyledRemote = styled('div')<{ isSmallScreen: boolean }>`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0;

    ${({ isSmallScreen }) =>
        !isSmallScreen &&
        css`
            border-left: 1px solid ${grey5};
            padding-left: 16px;
        `}
`;

enum Sections {
    TOUCH = 'touch',
    OFFICES = 'offices'
}

const AnchorCard: React.FC<{ children: React.ReactNode; href: string } & BoxProps> = (props) => {
    const { children, href, ...rest } = props;

    const goTo = (e) => {
        if (e.type === 'click' || (e.type === 'keydown' && e.keyCode === 13)) {
            navigate(href);
        }
    };

    return (
        <StyledBox role={'link'} tabIndex={0} onClick={goTo} onKeyDown={goTo} {...rest}>
            {children}
        </StyledBox>
    );
};

const ContactPage = ({ data }) => {
    const touchRef = useRef(null);
    const officesRef = useRef(null);

    const { title, subtitle } = data.page;
    const { t } = useTranslation();

    const [isVisibleOb, setIsVisibleOb] = useState(null);

    const isVisible = (inView: boolean, entry: any) => {
        setIsVisibleOb({ inView: inView, entry: entry });
    };

    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    return (
        <>
            <SwTopNav highlightedPage={data.highlightedPage?.nodes?.at(0)} latestUpdates={data.latestArticles.nodes} />
            <SwWebsiteContainer>
                <SwLayout>
                    <Page>
                        <SwContainer withMaxWidth={true}>
                            <Header>
                                <SwGrid columns={{ xs: ['100%'], md: ['60%', 'auto'] }} gap={32}>
                                    <SwTypography color={grey3} component={'h1'} variant={'h3'}>
                                        <SwTypography color={black} component={'span'} variant={'h3'}>
                                            {title}.
                                        </SwTypography>{' '}
                                        {subtitle}
                                    </SwTypography>
                                </SwGrid>
                            </Header>
                        </SwContainer>

                        <SwSubMenu
                            isVisibleEntry={isVisibleOb}
                            refs={[touchRef, officesRef]}
                            tabs={[t('contact_page.nav_tab_1'), t('contact_page.nav_tab_2')]}
                            tabsId={[Sections.TOUCH, Sections.OFFICES]}
                            withMaxWidth={true}
                        />

                        <SwSpacer direction={'vertical'} spacing={5} />

                        <InView as={'div'} id={Sections.TOUCH} rootMargin={'-64px 0px 0px 0px'} onChange={isVisible}>
                            <div ref={touchRef}>
                                <StyledContainer withMaxWidth={true}>
                                    <Grid container={true}>
                                        <Box position={'relative'} width={'100%'}>
                                            <StyledGrid item={true} md={8} xs={12}>
                                                <AnchorCard href={'/get-in-touch'}>
                                                    <SwSectionCard hideLine={true} open={true} openable={false}>
                                                        <Box
                                                            alignItems={'center'}
                                                            display={'flex'}
                                                            justifyContent={'space-between'}
                                                        >
                                                            <Box>
                                                                <SwTypography
                                                                    bold={true}
                                                                    component={'h2'}
                                                                    variant={'h4'}
                                                                >
                                                                    {t('contact_page.contact_card_1_title')}
                                                                </SwTypography>
                                                                <SwTypography color={grey3}>
                                                                    {t('contact_page.contact_card_1_subtitle')}
                                                                </SwTypography>
                                                            </Box>
                                                            <ArrowRight />
                                                        </Box>
                                                    </SwSectionCard>
                                                </AnchorCard>

                                                <SwSpacer direction={'vertical'} spacing={1.5} />

                                                <AnchorCard href={'/jobs'}>
                                                    <SwSectionCard hideLine={true} open={true} openable={false}>
                                                        <Box
                                                            alignItems={'center'}
                                                            display={'flex'}
                                                            justifyContent={'space-between'}
                                                        >
                                                            <Box>
                                                                <SwTypography
                                                                    bold={true}
                                                                    component={'h2'}
                                                                    variant={'h4'}
                                                                >
                                                                    {t('contact_page.contact_card_2_title')}
                                                                </SwTypography>
                                                                <SwTypography color={grey3}>
                                                                    {t('contact_page.contact_card_2_subtitle')}
                                                                </SwTypography>
                                                            </Box>
                                                            <ArrowRight />
                                                        </Box>
                                                    </SwSectionCard>
                                                </AnchorCard>

                                                <SwSpacer direction={'vertical'} spacing={1.5} />

                                                <SwSectionCard hideLine={true} open={true} openable={false}>
                                                    <SwTypography bold={true} component={'h2'} variant={'h4'}>
                                                        {t('contact_page.contact_card_3_title')}
                                                    </SwTypography>
                                                    <SwTypography color={grey3}>
                                                        <Trans i18nKey={'contact_page.contact_card_3_subtitle'}>
                                                            <SwLink
                                                                color={grey3}
                                                                href={'mailto:press@sweep.net'}
                                                                noGatsbyLink={true}
                                                                underlined={true}
                                                                variant={'body2'}
                                                            />
                                                        </Trans>
                                                    </SwTypography>
                                                </SwSectionCard>

                                                <SwSpacer direction={'vertical'} spacing={1.5} />

                                                <SwSectionCard hideLine={true} open={true} openable={false}>
                                                    <SwTypography bold={true} component={'h2'} variant={'h4'}>
                                                        {t('contact_page.contact_card_4_title')}
                                                    </SwTypography>
                                                    <SwTypography color={grey3}>
                                                        <Trans i18nKey={'contact_page.contact_card_4_subtitle'}>
                                                            <SwLink
                                                                color={grey3}
                                                                href={'mailto:hello@sweep.net'}
                                                                noGatsbyLink={true}
                                                                underlined={true}
                                                                variant={'body2'}
                                                            />
                                                        </Trans>
                                                    </SwTypography>
                                                </SwSectionCard>
                                            </StyledGrid>

                                            <StyledImageDiv isSmallScreen={isSmallScreen}>
                                                <StyledImage
                                                    alt={'Communicate Hero'}
                                                    isSmallScreen={isSmallScreen}
                                                    src={communicateHero}
                                                    width={'100%'}
                                                />
                                            </StyledImageDiv>
                                        </Box>
                                    </Grid>
                                </StyledContainer>
                            </div>
                        </InView>

                        <SwLine color={grey5} direction={'horizontal'} spacingBottom={4} />

                        <InView as={'div'} id={Sections.OFFICES} rootMargin={'-604px 0px 0px 0px'} onChange={isVisible}>
                            <div ref={officesRef}>
                                <SwContainer withMaxWidth={true}>
                                    <Grid container={true}>
                                        <Grid item={true} md={12}>
                                            <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                                                <SwTypography component={'h2'} variant={'h3'}>
                                                    {t('contact_page.offices_section_title')}
                                                </SwTypography>
                                                <SwTypography color={grey3} variant={'body1'}>
                                                    {t('contact_page.offices_section_subtitle')}
                                                </SwTypography>
                                                <SwSpacer direction={'vertical'} spacing={2} />
                                            </Box>
                                        </Grid>
                                        <Grid container={true} spacing={2}>
                                            <Grid item={true} md={3} xs={12}>
                                                <SwSectionCard hideLine={true} open={true} openable={false}>
                                                    <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                                                        <SwTypography component={'h3'} variant={'h4'}>
                                                            Paris
                                                        </SwTypography>
                                                        <SwTypography color={grey3}>
                                                            34 rue Laffitte <br />
                                                            75009 Paris <br />
                                                            France
                                                        </SwTypography>
                                                    </Box>
                                                </SwSectionCard>
                                            </Grid>
                                            <Grid item={true} md={3} xs={12}>
                                                <SwSectionCard hideLine={true} open={true} openable={false}>
                                                    <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                                                        <SwTypography component={'h3'} variant={'h4'}>
                                                            London
                                                        </SwTypography>
                                                        <SwTypography color={grey3}>
                                                            Chancery House <br />
                                                            53-64 Chancery Lane <br />
                                                            Holborn <br />
                                                            London, WC2A 1QS <br />
                                                            United Kingdom
                                                        </SwTypography>
                                                    </Box>
                                                </SwSectionCard>
                                            </Grid>
                                            <Grid item={true} md={3} xs={12}>
                                                <SwSectionCard hideLine={true} open={true} openable={false}>
                                                    <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                                                        <SwTypography component={'h3'} variant={'h4'}>
                                                            Montpellier
                                                        </SwTypography>
                                                        <SwTypography color={grey3}>
                                                            80 Rue Isabelle Eberhardt <br />
                                                            34000 Montpellier <br />
                                                            France
                                                        </SwTypography>
                                                    </Box>
                                                </SwSectionCard>
                                            </Grid>
                                            <Grid item={true} md={3} xs={12}>
                                                <StyledRemote isSmallScreen={isSmallScreen}>
                                                    <SwTypography color={grey3} component={'h3'} variant={'h4'}>
                                                        Working remotely
                                                    </SwTypography>

                                                    <SwSpacer direction={'vertical'} spacing={1} />

                                                    <SwTypography color={grey3}>
                                                        Most of us work from home, and we're spread all around the
                                                        world. We're in 10+ countries and counting.
                                                    </SwTypography>
                                                </StyledRemote>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </SwContainer>
                            </div>
                        </InView>
                        <SwWebsiteFooter newsletterForm={data.newsletterForm} />
                    </Page>
                </SwLayout>
            </SwWebsiteContainer>
        </>
    );
};

export const Head = ({
    data: {
        page: { seo, slug }
    }
}) => (
    <Seo
        description={seo.description}
        image={seo.image?.url}
        noIndex={seo.noIndex}
        pathname={`/${slug}`}
        title={seo.title}
    />
);

export const query = graphql`
    query getContactPageInformation($locale: GraphCMS_Locale) {
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: en }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        page: graphCmsPage(slug: { eq: "contact" }, locale: { eq: $locale }) {
            id
            remoteId
            slug
            subtitle
            title
            seo {
                ...Essentials_GraphCMS_Seo
            }
        }
    }
`;

export default ContactPage;
